import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useIdleTimer } from 'react-idle-timer'
import { useTranslation } from 'react-i18next';

import { logout } from '../utils/Utils';

import ErrorModal from '../components/ErrorModal';
import UploadTable from '../components/UploadTable';
import AccountTable from '../components/AccountTable';


const Payments = () => {
  const { t } = useTranslation(['translation', 'modal']);

  // Error Popups
  const [errorValue, setErrorValue] = React.useState({"title": "Error", "body":"An error occurred, try again later."});
  const [showError, setShowError] = React.useState(false);
  const errorMan = (t, b) => {setErrorValue({"title": t, "body": b}); setShowError(true)  };


  // ####################### auto logout #######################
  const onAction = (event) => {
    idleTimer.reset();
  }

  function onPrompt() {
    errorMan(
      t("inactive-modal.title", {ns: "modal"}), 
      t("inactive-modal.body", {ns: "modal"}));
  };

  function onIdle() {
    console.debug("User is inactive:");
    logout();
  }

  const idleTimer = useIdleTimer({
    timeout: 10 * (1000 * 60)  , // 10 minutes
    promptBeforeIdle: 30 * (1000), // 30s before timeout show prompt
    startOnMount: true,
    eventsThrottle: 500,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
    ],
    onIdle,
    onPrompt,
    onAction,
  });


  // ####################### Page Layout #######################
  return (
    <Container className="mt-5 flex-grow-1">
      <ErrorModal show={showError} setShow={setShowError} titleText={errorValue["title"]} errorText={errorValue["body"]}/>
      <Row>
        <Col md={12}>
          <h1 className="mb-3">{t("page-title.payments")}</h1>
        </Col>
      </Row>
      <AccountTable errorMan={errorMan} />
      <UploadTable errorMan={errorMan} />
    </Container>
  );
};


export default Payments;
