import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import KeycloakContext from './utils/KeycloakContext';

import Navigation from './components/Navbar';
import FooterNav from './components/Footer';

// import Home from './pages/Home';
import Payments from './pages/Payments';
// import Help from './pages/Help';


function App() {
  const { isAuthenticated } = useContext(KeycloakContext);

  if (!isAuthenticated) {
    return <div></div>;
  }

  return (
    <Router>
      <Navigation />
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        {/* <Route path="/payments" element={<Payments />} /> */}
        <Route path="/" element={<Payments />} />
        {/* <Route path="/help" element={<Help />} /> */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <FooterNav />
    </Router>
  );
}

export default App;
