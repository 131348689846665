import React from 'react';
import moment from 'moment';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import TooltipButton from '../components/TooltipButton'; // from 'react-bootstrap-tooltip-button'

import { useTranslation } from 'react-i18next';

import { getAccounts, getTransactionsForAccount } from '../utils/BackendFunctions';

import PredefinedRanges from '../components/PredefinedRange';


const AccountTable = ({ errorMan }) => {
  const { t } = useTranslation(['translation', 'modal']);

  // Account Table
  const [rows, setRows] = React.useState(<tr><td colSpan={7}>{t("account-table.loading")}</td></tr>);
  const [accountData, setAccountData] = React.useState([]);
  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const [refresh, setRefresh] = React.useState(0);
  // Export Button
  const [selectedRange, setSelectedRange] = React.useState([moment().subtract(3, 'months'), moment()]);
  const [exportType, setExportType] = React.useState('default');
  const [downloadButtonActive, setDownloadButtonActive] = React.useState(false);


  // ####################### Input Processing #######################
  function getAccountId() {
    const data = accountData[selectedRowId];
    return data.account_number + "-" + data.bsb + "-AU";
  }


  // ####################### Account Data #######################
  React.useEffect(() => {
    const updateRefresh = () => {setRefresh(refresh + 1)};
    setTimeout(updateRefresh, 5 * (1000 * 60)); // 5m 

    async function fetchData() {
      var rowData;
      let savedData = sessionStorage.getItem("accountList");
      if (savedData != null && refresh === 0) { // only on initial load of page
        console.debug("Loading Cache: #" + refresh);
        rowData = JSON.parse(savedData);

      } else {
        console.debug("Refreshing: #" + refresh);
        rowData = await getAccounts(errorMan);
      }
      
      try {
        rowData.sort((a, b) => {
          if (a.customer_id < b.customer_id) {
            return -1;
          } else if (a.customer_id > b.customer_id) {
            return 1;
          } else {
            return 0;
          }
        });

        let data = rowData.map(
          (row, index) => 
            <tr key={"row" + index}>
              <td key={"select" + index}><input name="select-radio" type="radio" onClick={() => {setSelectedRowId(index); setDownloadButtonActive(true)}}/></td>
              <td key={"id" + index}>{row.customer_id}</td> {/* may be confusing */}
              <td key={"customer" + index}>{row.customer}</td>
              <td key={"bsb" + index}>{row.bsb}</td>
              <td key={"account_number" + index}>{row.account_number}</td>
              <td key={"type" + index}>{row.type}</td>
              <td className="text-end" key={"balance" + index}>{row.balance}</td>
            </tr>
          );
        setAccountData(rowData);
        setRows(data);
  
        sessionStorage.setItem("accountList", JSON.stringify(rowData));
      } catch (error) {
        setRows(<tr><td colSpan={7}>{t("account-table.error")}</td></tr>)
      }
    }
    fetchData();
  }, [refresh]);

  // ####################### Component Layout #######################
  return (
    <div><Row> {/* <Account Table> */}
        <Col md={12}>
        <Card className="mb-4">
            <Card.Body>
            <Card.Title>{t("account-table.title")}</Card.Title>
            <Table striped="columns" responsive="md">
                <thead>
                <tr key={"header"}>
                    <th key={"select"}></th>
                    <th key={"id"}>{t("account-table.id")}</th>
                    <th key={"customer"}>{t("account-table.customer")}</th>
                    <th key={"bsb"}>{t("account-table.bsb")}</th>
                    <th key={"account_number"}>{t("account-table.account-number")}</th>
                    <th key={"type"}>{t("account-table.type")}</th>
                    <th key={"balance"}>{t("account-table.balance")}</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>  
            </Table>
            </Card.Body>
        </Card>
        </Col>
    </Row>
    <Row> {/* <Download Card> */}
        <Col md={12}>
        <Card className="mb-4">
            <Card.Body>
            <Card.Title>{t("download-card.title")}</Card.Title>
            <Card.Text>
                {t("download-card.body")}
            </Card.Text>
            <Row>
                <Col>
                <PredefinedRanges val={selectedRange} setVal={setSelectedRange} />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                <ButtonGroup>
                    <TooltipButton
                    title={t('download-card.button.title')}
                    onClick={() => {getTransactionsForAccount(getAccountId(), selectedRange, exportType, errorMan)}}
                    disabled={!downloadButtonActive}
                    tooltipText={t("download-card.button.disabled-tooltip")}
                    />                    
                    <Dropdown onSelect={(t) => setExportType(t)}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {t("download-card.types." + exportType)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant='secondary'>
                        <Dropdown.Item eventKey={"default"}>{t("download-card.types-long.default")}</Dropdown.Item>
                        <Dropdown.Item eventKey={"xero"}>{t("download-card.types-long.xero")}</Dropdown.Item>
                        <Dropdown.Item eventKey={"quickbooks"}>{t("download-card.types-long.quickbooks")}</Dropdown.Item>
                        <Dropdown.Item eventKey={"myob"}>{t("download-card.types-long.myob")}</Dropdown.Item>
                    </Dropdown.Menu>
                    </Dropdown>
                </ButtonGroup>
                </Col>
            </Row>
            </Card.Body>
        </Card>
        </Col>
    </Row></div>
  );
}

export default AccountTable;
