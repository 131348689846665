import Keycloak from 'keycloak-js';


const keycloakConfig = {
  url: window._env_.AUTH_URL, 
  realm: window._env_.AUTH_REALM, 
  clientId: window._env_.CLIENT_ID
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
