import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

import { useTranslation } from 'react-i18next';


const FooterNav = () => {
    const { t } = useTranslation();
   
    return (
        <Navbar className="nav-footer" bg="light">
            <Container>
                <Navbar.Brand className='nav-footer-logo'>
                <img
                    src="logo.svg" 
                    height="60" 
                    className="d-inline-block align-top"
                    alt="Internet Banking Logo"
                />
                </Navbar.Brand>
                <Navbar.Text className='nav-footer-text'>{t("navbar.footer")}</Navbar.Text>
            </Container>
        </Navbar>
    );
};

export default FooterNav;
