// import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';

import {KeycloakProvider} from './utils/KeycloakContext';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './global.css';

import './i18n';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    // <StrictMode>
        <KeycloakProvider>
            <App />
        </KeycloakProvider>
    // </StrictMode>
);
