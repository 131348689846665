import React from 'react';
import { Button, Modal } from 'react-bootstrap';

function ErrorModal({show, setShow, titleText, errorText}) {
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    return (
      <Modal size={"lg"} show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{titleText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorText}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  export default ErrorModal;
  