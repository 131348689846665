import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';
import Button from 'react-bootstrap/Button';
import TooltipButton from '../components/TooltipButton'; // from 'react-bootstrap-tooltip-button'

import { useTranslation } from 'react-i18next';

import { getExampleCSV, getSchema, validateTransactions, uploadTransaction } from '../utils/BackendFunctions';


const UploadTable = ({ errorMan }) => {
  const { t } = useTranslation(['translation', 'modal']);

  // Upload Table
  const [uploadRows, setUploadRows] = React.useState(null);
  const [uploadData, setUploadData] = React.useState([]);
  const [rowSelected, setRowSelected] = React.useState([]);
  const [rowDisabled, setRowDisabled] = React.useState([]);
  // Upload Button
  const [uploadButtonActive, setUploadButtonActive] = React.useState(false);
  // Upload file field
  const fileBoxRef = React.useRef(null);

  const [refresh, setRefresh] = React.useState(0);



  // ####################### upload card #######################
  async function handleUploadFile() {
    const formData = new FormData();
    const fileField = document.querySelector("input[type=file]");
    formData.append("file", fileField.files[0]);

    // clear input box
    fileBoxRef.current.value = '';
    setUploadButtonActive(false);
    
    let transactions = await validateTransactions(formData, errorMan);

    setUploadData(uploadData.concat(transactions));
    setRefresh(refresh => refresh + 1);
  }


  // ####################### transaction table #######################
  function updateBit(index) {
    rowSelected[index] = !rowSelected[index];
    setRefresh(refresh => refresh + 1);
  }

  function updateAllBits(e) {
    for (let i = 0; i < uploadData.length; i++) {
      rowSelected[i] = e.target.checked && !rowDisabled[i];
    }
    
    setRefresh(refresh => refresh + 1);
  }

  async function disableRow(index) {
    rowSelected[index] = false;
    rowDisabled[index] = true;

    sessionStorage.setItem("disabledList", JSON.stringify(rowDisabled));
    setRefresh(refresh => refresh + 1);
  }

  function removeTransaction(index) {
    // TODO: for some reason doesn't delete last element
    uploadData.splice(index, 1);
    rowSelected.splice(index, 1);
    rowDisabled.splice(index, 1);

    setRefresh(refresh => refresh + 1);
  }

  function handleSubmit() {
    // prepare for submission
    let toSubmit = [];
    let submitIndex = [];
    for (let i = 0; i < uploadData.length; i ++) {
      if (rowSelected[i]) {
        toSubmit.push(uploadData[i]);
        submitIndex.push(i);
        uploadData[i]["Status"] = "upload-table.status.waiting";
      }
    }
    setRefresh(refresh => refresh + 1);

    // Submit each
    for (let i = 0; i < toSubmit.length; i ++) {
      uploadData[submitIndex[i]]["Status"] = "upload-table.status.sent";
      disableRow(submitIndex[i]);
      setRefresh(refresh => refresh + 1);
      
      uploadTransaction(toSubmit[i], errorMan).then(
        (res) => {
          uploadData[submitIndex[i]]["Status"] = res;
          disableRow(submitIndex[i]);
        }
      );
    }

    setRefresh(refresh => refresh + 1);
  }

  function generateRow(row, index) {
      let numString = Number(row.Amount).toLocaleString(undefined, {
          style: "currency",
          currency: "AUD",
          minimumFractionDigits: 2,
          currencyDisplay: "symbol"
      });

    return (
      <tr key={"row" + index}>
        <td key={"select" + index}><FormCheck onChange={()=>{updateBit(index)}} checked={rowSelected[index]} disabled={rowDisabled[index]}></FormCheck></td>
        <td key={"status" + index}>{t(row.Status)}</td>
        <td key={"accountID" + index}>{row.AccountID}</td>
        <td className="text-end" key={"amount" + index}>{numString}</td>
        <td key={"currency" + index}>{row.Currency}</td>
        <td key={"startDate" + index}>{row.StartDate}</td>
        <td key={"endDate" + index}>{row.EndDate}</td>
        <td key={"frequency" + index}>{row.Frequency}</td>
        <td key={"frequencyValue" + index}>{row.FrequencyValue}</td>
        <td key={"recipientAccountID" + index}>{row.RecipientAccountID}</td>
        <td key={"recipientHolderName" + index}>{row.RecipientHolderName}</td>
        <td key={"removeButton" + index}><Button className={"remove"} onClick={()=>removeTransaction(index)}>✖</Button></td>
     </tr>
    );
  }

  React.useEffect(() => {
    async function makeTable() {
      var rowData;
      let savedData = sessionStorage.getItem("transactionList");
      let selectedData = sessionStorage.getItem("selectedList");
      let disabledData = sessionStorage.getItem("disabledList");
      if (savedData != null && refresh === 0) { // only on initial load of page
        rowData = JSON.parse(savedData).concat(uploadData);
        setUploadData(rowData);
        setRowSelected(JSON.parse(selectedData));
        setRowDisabled(JSON.parse(disabledData));
      } else {
        rowData = uploadData;
      }

      // try {
        if (rowData.length === 0) {
          setUploadRows(<tr><td colSpan={12}>{t("upload-table.empty")}</td></tr>);
          return;
        }
        for (let i = 0; i < rowData.length; i++) { // initialise values
          if (rowSelected[i] === undefined) {
            rowSelected[i] = false;
          }
          if (rowDisabled[i] === undefined) {
            rowDisabled[i] = false;

            if (rowData[i].Status.includes("sent") || rowData[i].Status.includes("rejected") || rowData[i].Status.includes("accepted")) {
              rowDisabled[i] = true;
            }
          }
        }

        let tableRow = rowData.map(generateRow);

        setUploadRows(tableRow);
        // sessionStorage.removeItem("transactionList");
        sessionStorage.setItem("transactionList", JSON.stringify(rowData));

        // sessionStorage.removeItem("selectedList");
        sessionStorage.setItem("selectedList", JSON.stringify(rowSelected));

        // sessionStorage.removeItem("disabledList");
        sessionStorage.setItem("disabledList", JSON.stringify(rowDisabled));
        // } catch (error) { // FIXME: hides errors
        //   console.log(error);
        //   setUploadRows(<tr><td colSpan={12}>{t("upload-table.error")}</td></tr>);
        // }
    }

    makeTable();
  }, [ refresh ]);

  // ####################### Component Layout #######################
  return (
    <div><Row> {/* <Upload Card> */}
      <Col md={12}> 
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>{t("upload-card.title")}</Card.Title>
            <Card.Text>
              {t("upload-card.body")}
            </Card.Text>
            <Form.Group controlId="formFile">
              <Form.Control ref={fileBoxRef} type="file" className="mb-2" onChange={() => {setUploadButtonActive(true)}}/>
              <TooltipButton
                    title={t('upload-card.submit-button.title')}
                    onClick={handleUploadFile}
                    disabled={!uploadButtonActive}
                    tooltipText={t("upload-card.submit-button.disabled-tooltip")}
                  />      
              <Button variant="secondary" className="button" onClick={() => {getExampleCSV(errorMan)}}>{t("upload-card.csv-button")}</Button>
              {/* <Button variant="secondary" className="button" onClick={() => {getSchema(errorMan)}}>{t("upload-card.schema-button")}</Button> */}
            </Form.Group>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <Row> {/* <Upload Table> */}
      <Col md={12}>
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>{t("upload-table.title")}</Card.Title>
            <Table striped="columns" responsive size="sm">
              <thead>
                <tr key={"header"}>
                  <th key={"select"}><FormCheck onChange={(e)=>{updateAllBits(e)}}></FormCheck></th>
                  <th key={"Status"}>{t("upload-table.status.header")}</th>
                  <th key={"accountID"}>{t("upload-table.id")}</th>
                  <th key={"amount"}>{t("upload-table.amount")}</th>
                  <th key={"currency"}>{t("upload-table.currency")}</th>
                  <th key={"startDate"}>{t("upload-table.start-date")}</th>
                  <th key={"endDate"}>{t("upload-table.end-date")}</th>
                  <th key={"frequency"}>{t("upload-table.frequency")}</th>
                  <th key={"frequencyValue"}>{t("upload-table.frequency-value")}</th>
                  <th key={"recipientAccountID"}>{t("upload-table.recipient-id")}</th>
                  <th key={"recipientHolderName"}>{t("upload-table.recipient-name")}</th>
                  <th key={"removeButton"}>{t("upload-table.remove")}</th>
                </tr>
              </thead>
              <tbody>
                {uploadRows}
              </tbody>  
            </Table>
            <Button onClick={handleSubmit}>{t('upload-table.button.submit')}</Button>
          </Card.Body>
        </Card>
      </Col>
    </Row></div>
  );
}

export default UploadTable;