import downloadMin from 'downloadjs';

import keycloak from './KeycloakService';


const logout = () => {
    sessionStorage.clear();
    keycloak.logout({"redirectUri": window._env_.POST_LOGOUT_URL});
};


function serveJSONFile(data, filename, mime) {
    const blob = new Blob([JSON.stringify(data, null, 2)], {
      type: mime,
    });
  
    downloadMin(blob, filename, mime);
};


function serveFile(data, filename, mime) {
    const dataArray = [data];
    const blob = new Blob(dataArray, {
      type: mime,
    });
  
    downloadMin(blob, filename, mime);
};


function hashString(str) {
  let hash = 5381;

  for (let i = 0; i < str.length; i++) {
    hash = (hash * 33) ^ str.charCodeAt(i);
  }

  return hash;
}


function getIP() {
  let prevIP = sessionStorage.getItem("IP");
  if (prevIP !== null && prevIP !== undefined ) {
    return prevIP;
  }

  fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => {
      sessionStorage.setItem("IP", data.ip);
      return data.ip;
  }) 
  .catch(error => {
      sessionStorage.setItem("IP", "IP_UNKNOWN");
      return "IP_UNKNOWN";
  });
}


export {logout}
export {serveJSONFile}
export {serveFile}
export {hashString}
export {getIP}
