import i18n from '../i18n';

import keycloak from './KeycloakService';
import { getIP, serveFile, serveJSONFile } from './Utils';


// get the user's IP
getIP();


const t = (key, options) => {
    let ops = Object.assign({}, options, { ns: "modal" });
    return i18n.t(key, ops);
};


async function getCSRF() {
    const token = keycloak.token;

    var response = await fetch(window._env_.BACKEND_HOST + window._env_.LOGIN_ENDPOINT, {
        headers: {
            "Authorization": "Bearer " + token,
            "X-IP": getIP()
        }
    });

    const tokenCSRF = await response.json();
    return [ tokenCSRF["csrf"], tokenCSRF["uuid"] ];
}


async function getSchema(errorMan) { 
    const token = keycloak.token;

    let schema, response;
    try {
        response = await fetch(window._env_.BACKEND_HOST + window._env_.SCHEMA_ENDPOINT, {
        headers: {
            "Authorization": "Bearer " + token,
            "X-IP": getIP()
        }
    });
        schema = await response.json();
    } catch { // catches network errors
        errorMan(
            t("error-modal.title", {task: "schema"}),
            t("error-modal.body.connection")
        );
        return;
    }
    
    if (response.status !== 200) {
        errorMan(
            t("error-modal.title", {task: "schema"}),
            [
                t("error-modal.body.unspecified", {task: "schema"}), <br/>,
                t("error-modal.code", {code: response.status})
            ]
        );
        return;
    }

    serveJSONFile(schema, "scheduled.csv-metadata.json", "application/csvm+json");
}


async function getExampleCSV(errorMan) {
    const token = keycloak.token;

    let exampleFile, response;
    try {
        response = await fetch(window._env_.BACKEND_HOST + window._env_.EXAMPLE_ENDPOINT, {
            headers: {
                "Authorization": "Bearer " + token,
                "X-IP": getIP()
            }
        });
        exampleFile = await response.text();
    } catch { // catches network errors
        errorMan(
            t("error-modal.title", {task: "csv"}),
            t("error-modal.body.connection")
        );
        return;
    }
    
    if (response.status !== 200) {
        errorMan(
            t("error-modal.title", {task: "csv"}),
            [
                t("error-modal.body.unspecified", {task: "csv"}), <br/>,
                t("error-modal.code", {code: response.status})
            ]
        );
        return;
    }

    serveFile(exampleFile, "scheduled.csv", "text/csv");
}


async function getAccounts(errorMan) {
    const token = keycloak.token;

    let accounts, response;
    try {
        response = await fetch(window._env_.BACKEND_HOST + window._env_.ACCOUNTS_ENDPOINT, {
            headers: {
                "Authorization": "Bearer " + token,
                "X-IP": getIP()
            }
        });
        accounts = await response.json();
        accounts = accounts["data"];
    } catch { // catches network errors
        errorMan(
            t("error-modal.title", {task: "get-accounts"}),
            t("error-modal.body.connection")
        );
        return;
    }
    
    if (response.status !== 200) {
        errorMan(
            t("error-modal.title", {task: "get-accounts"}),
            [
                t("error-modal.body.unspecified", {task: "get-accounts"}), <br/>,
                t("error-modal.code", {code: response.status})
            ]
        );
        return;
    }

    // Localise balances to common format
    for (let acc of accounts) {
        let numString = Number(acc.balance).toLocaleString(undefined, {
            style: "currency",
            currency: "AUD",
            minimumFractionDigits: 2,
            currencyDisplay: "symbol"
        });

        acc.balance = numString;
    }

    return accounts;
}


async function getTransactionsForAccount(accountID, range, type, errorMan) {
    const token = keycloak.token;
    const start = range[0].valueOf();
    const end = range[1].valueOf();
    
    // normalise types
    if (type === "Format") {
        type = "Default";
    }

    let transactionFile, response;
    try {
        response = await fetch(window._env_.BACKEND_HOST + window._env_.TRANSACTIONS_ENDPOINT + accountID 
                                + "?start=" + start + "&end=" + end + "&dtype=" + type, {
            headers: {
                "Authorization": "Bearer " + token,
                "X-IP": getIP()
            }
        });
        transactionFile = await response.json();
        transactionFile = transactionFile["data"];
    } catch { // catches network errors
        errorMan(
            t("error-modal.title", {task: "get-transactions"}),
            t("error-modal.body.connection")
        );
        return;
    }

    if (response.status !== 200) {
        errorMan(
            t("error-modal.title", {task: "get-transactions"}),
            [
                t("error-modal.body.unspecified", {task: "get-transactions"}), <br/>,
                t("error-modal.code", {code: response.status})
            ]
        );
        return;
    }

    const date = new Date();

    if (type === "myob") {
        const filename = accountID + "_" + date.toISOString().slice(0, 10) + "_transactions.qif";
        serveFile(transactionFile, filename, "application/qif");
    } else {
        const filename = accountID + "_" + date.toISOString().slice(0, 10) + "_transactions.csv";
        serveFile(transactionFile, filename, "text/csv");
    }
}


async function validateTransactions(formData, errorMan) {
    const token = keycloak.token;

    // get csrf/nonce
    const [csrf, uuid] = await getCSRF();
    formData.append("csrf_token", csrf);
    formData.append("uuid", uuid);

    
    // send upload request
    var result, response;
    try {
        response = await fetch(window._env_.BACKEND_HOST + window._env_.TRANSACTIONS_VALIDATE_ENDPOINT, {
            headers: {
                "Authorization": "Bearer " + token,
                "X-IP": getIP()
            },
            method: "POST",
            body: formData,
        });
        result = await response.json();
    } catch (error) {
        errorMan(
            t("error-modal.title", {task: "post-transactions"}),
            t("error-modal.body.connection")
        );
        return [];
    }

    
    // process backend response
    if (response.status === 422) {
        var numErrors = result["detail"]["errors"].length;
        var errorString = [numErrors + " error(s) found.", <br />];
        for (const error of result["detail"]["errors"]) {
            errorString = errorString.concat([error["message"], <br />]);
        }
        errorMan(
            t("error-modal.title", {task: "post-transactions.validation"}),
            errorString
        );
        return [];
    } else if (!((200 <= response.status) && (response.status <= 299))) {
        errorMan(
            t("error-modal.title", {task: "post-transactions"}),
            [
                t("error-modal.body.unspecified", {task: "post-transactions"}), <br/>,
                t("error-modal.code", {code: response.status})
            ]
        );
        return [];
    }

    return result["orders"];
}


async function uploadTransaction(transactionData, errorMan) {
    const token = keycloak.token;

    // get csrf/nonce
    const [csrf, uuid] = await getCSRF();
    
    // send upload request
    var result, response;
    try {
        response = await fetch(window._env_.BACKEND_HOST + window._env_.TRANSACTIONS_ENDPOINT, {
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json",
                "X-IP": getIP()
            },
            method: "POST",
            body: JSON.stringify({
                "transaction_data": transactionData,
                "csrf_token": csrf,
                "uuid": uuid
            }),
        });
        result = await response.json();
    } catch (error) {
        errorMan(
            t("error-modal.title", {task: "post-transactions"}),
            t("error-modal.body.connection")
        );
        return;
    }

    
    // process backend response
    if (response.status === 400) {
        // var numErrors = result["detail"]["errors"].length;
        // var errorString = [numErrors + " error(s) found.", <br />];
        // for (const error of result["detail"]["errors"]) {
        //     errorString = errorString.concat([error["message"], <br />]);
        // }
        // errorMan(
        //     t("error-modal.title", {task: "post-transactions.validation"}),
        //     errorString
        // );
        return t("upload-table.status.rejected");
    } else if (!((200 <= response.status) && (response.status <= 299))) {
        errorMan(
            t("error-modal.title", {task: "post-transactions"}),
            [
                t("error-modal.body.unspecified", {task: "post-transactions"}), <br/>,
                t("error-modal.code", {code: response.status})
            ]
        );
        return t("upload-table.status.rejected");
    }

    return t("upload-table.status.accepted");
}


export {getCSRF};
export {getSchema};
export {getExampleCSV}
export {getAccounts};
export {getTransactionsForAccount};
export {validateTransactions};
export {uploadTransaction}