import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { i18nextPlugin } from 'translation-check'

i18n
    // .use(i18nextPlugin)
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(ChainedBackend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        // supportedLngs: ['en', 'zh-CN'],
        nonExplicitSupportedLngs: true,
        debug: false,

        backend: {
            backends: [LocalStorageBackend, HttpBackend],
            backendOptions: [],
            cacheHitMode: 'refreshAndUpdateStore',
        },

        interpolation: {
            skipOnVariables: false
        },

        react: {
            bindI18nStore: 'added', // this way, when the HttpBackend delivers new translations (thanks to refreshAndUpdateStore), the UI gets updated
            useSuspense: false
        }
    });

export default i18n;
