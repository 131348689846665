import React from 'react';
import moment from 'moment';
import $ from "jquery";
import 'daterangepicker';

import 'daterangepicker/daterangepicker.css';
import 'font-awesome/css/font-awesome.min.css';

import { withTranslation } from 'react-i18next';


class PredefinedRanges extends React.Component {
  constructor(props) {
    super(props);
    const setRange = props["setVal"];
    const { t } = this.props; 

    $(function() {
      var start = props['val'][0];
      var end = props['val'][1];

      function cb(start, end) {
          $('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
          setRange([start, end]);
      }

      // console.debug(t("date-picker.locale.days-of-week", { returnObjects: true }))
      // console.debug(t("date-picker.locale.month-names", { returnObjects: true }))
      
      $('#reportrange').daterangepicker({
          startDate: start,
          endDate: end,
          ranges: {
              [t("date-picker.thirty-days")]: [moment().subtract(30, 'days'), moment()],
              [t("date-picker.ninety-days")]: [moment().subtract(3, 'months'), moment()],
              [t("date-picker.six-months")]: [moment().subtract(6, 'months'), moment()],
              [t("date-picker.twelve-months")]: [moment().subtract(12, 'months'), moment()],
              [t("date-picker.last-month")]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
              [t("date-picker.last-year")]: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
          },
          linkedCalendars: false,
          showDropdowns: true,
          drops: "auto",
          minDate: moment().date(1).month(6).subtract(3, "year"),
          maxDate: moment(),
          locale: {
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: t("date-picker.locale.apply-label"),
            cancelLabel: t("date-picker.locale.cancel-label"),
            fromLabel: t("date-picker.locale.from-label"),
            toLabel: t("date-picker.locale.to-label"),
            customRangeLabel: t("date-picker.locale.custom"),
            daysOfWeek: t("date-picker.locale.days-of-week", { returnObjects: true }),
            monthNames: t("date-picker.locale.month-names", { returnObjects: true }),
            firstDay: 1
          }
      }, cb);
  
      cb(start, end);
    });
  };

  
  render () {  
    return (
      <div id="reportrange" style={{"cursor":"pointer","padding":"5px 10px","border":"1px solid #ccc","width":"100%\""}}>
        <i className="fa fa-calendar"></i>&nbsp;
        <span></span> <i className="fa fa-caret-down"></i>
      </div>
    );
  };
}


export default withTranslation()(PredefinedRanges);
