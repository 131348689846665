import React, { createContext, useState, useEffect } from 'react';
import keycloak from './KeycloakService';
import { logout } from './Utils';


const KeycloakContext = createContext();

export const KeycloakProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        keycloak.init({ onLoad: 'login-required' })
            .then(authenticated => {
                setIsAuthenticated(authenticated);
            })
            .catch(error => {
                console.error('Keycloak init error:', error);
            });

        keycloak.onTokenExpired = () => {
            console.debug('Token expired');
            keycloak.updateToken(30)
                .then(() => {
                    console.debug('Successfully get a new token');
                })
                .catch((e) => {
                    logout();
                });
        }
    }, []);

    return (
        <KeycloakContext.Provider value={{ isAuthenticated }}>
            {children}
        </KeycloakContext.Provider>
    );
};


export default KeycloakContext;
