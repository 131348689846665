import React from 'react';
import { Navbar, Nav, Container, Dropdown, NavLink, NavItem } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

import { useTranslation } from 'react-i18next';

import keycloak from '../utils/KeycloakService';
import { logout } from '../utils/Utils';


const Navigation = () => {
  const { t, i18n } = useTranslation();

  const swapTable = {
    "en": "zh-CN",
    "en-US": "zh-CN", // no way into en-US
    "zh-CN": "en",
    "cimode": "en" // no way into en-US
  };

  const otherNames = {
    "en": "EN",
    "zh-CN": "中文",
    "cimode": "DEBUG"
  };

  // user profile card
  const preferredUsername = keycloak.tokenParsed ? keycloak.tokenParsed.preferred_username : 'Unknown';
  const name = keycloak.tokenParsed ? keycloak.tokenParsed.name : 'Unknown Name'; 
  // selected language
  const [langButtonText, setLangButtonText] = React.useState(nextLanguage());

  // ####################### Switch Language #######################
  function nextLanguage() {
    return otherNames[swapTable[i18n.language]];
  }

  function changeLanguage() {
    let lang = i18n.language;
    lang = swapTable[lang];
    i18n.changeLanguage(lang);
    document.cookie = `i18next = ${lang}`;

    setLangButtonText(nextLanguage);
  }

  return (
    <Navbar bg="light" expand="md">
      <Container>
        <Navbar.Brand href="/">
          <img
            src="logo.svg" 
            height="60" 
            className="d-inline-block align-top"
            alt="Internet Banking Logo"
          />
        </Navbar.Brand>
        <Navbar.Text className="mx-2 no-select" onClick={() => changeLanguage("en")}>
          <b>{langButtonText}</b>
        </Navbar.Text>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
          <Nav> {/* Pages */}
            {/* <Nav.Link as={NavLink} to="/">Home</Nav.Link> */}
            <Nav.Link to="/">{t("page-title.payments")}</Nav.Link>
            {/* <Nav.Link as={NavLink} to="/help">Help</Nav.Link> */}
          </Nav>
          <Dropdown className="justify-content-end custom-dropdown" as={NavItem}>
            <Dropdown.Toggle as={NavLink}>{name} ({preferredUsername})</Dropdown.Toggle>
            <Dropdown.Menu  align="end">
              <Dropdown.Item onClick={logout}>{t("navbar.logout")}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
